<template>
  <div>
    <a-card>
      <a-form ref="formRef" :model="formState" class="myform" :rules="rules">
        <a-row class="form-row" _style="margin-top:-20px;">
          <a-col :sm="24">
            <a-form-item
              label="Nama Produk"
              label-align="left"
              :label-col="{ sm: { span: 3 } }"
              :wrapper-col="{ sm: { span: 15 - 3 } }"
              :help="error.name.message"
              :validate-status="error.name.status"
              required
              name="name"
            >
              <a-input
                placeholder="Nama Produk"
                width="300px"
                v-model:value="name"
                :disabled="formState.readOnly"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row class="form-row" _style="margin-top:-20px;">
          <a-col :sm="24">
            <a-form-item
              label="Kode SAP"
              label-align="left"
              :label-col="{ sm: { span: 3 } }"
              :wrapper-col="{ sm: { span: 15 - 3 } }"
              :help="error.code.message"
              :validate-status="error.code.status"
              required
              name="code"
            >
              <a-input
                placeholder="Kode SAP"
                width="300px"
                v-model:value="code"
                :disabled="formState.readOnly"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <!-- kode referensi -->
        <a-row class="form-row mb-2">
          <a-col :sm="{ span: 24, offset: 3 }">
            <a-form class="myform-vertical" layout="vertical">
              <template v-for="(item, index) in codes.filter(i => !i.is_deleted)" :key="index">
                <a-row class="form-row">

                  <!-- code -->
                  <a-col :sm="4">
                    <a-form-item
                      label="Kode Referensi Produk :"
                      label-align="left"
                      :name="['reference', index, 'code']"
                      has-feedback>
                      <a-input
                        v-model:value="item.code"/>
                    </a-form-item>
                  </a-col>

                  <!-- name -->
                  <a-col :sm="4">
                    <a-form-item
                      label="Nama Produk :"
                      label-align="left"
                      :name="['reference', index, 'name']">
                      <a-input
                        v-model:value="item.name"/>
                    </a-form-item>
                  </a-col>

                  <a-col :sm="4">
                    <a-form-item label=" " label-align="left">
                      <a-button
                        @click="removeReferenceRow(item)"
                        type="danger"><i class="fa fa-remove"></i>
                      </a-button>
                    </a-form-item>
                  </a-col>
                </a-row>
              </template>
            </a-form>
            <!-- add function -->
            <template v-if="!formState.readOnly">
              <a-form-item
                label-align="left"
                :wrapper-col="{ sm: { span: 24 - 4 - 8 } }"
                :colon="false">
                <a-button type="primary" @click="addReferenceCode">
                  <i class="fa fa-plus"></i> Tambah Kode Referensi
                </a-button>
              </a-form-item>
            </template>
          </a-col>
        </a-row>

        <!-- satuan -->
        <a-form-item
          label="Satuan"
          label-align="left"
          required
          :label-col="{ sm: { span: 3 } }"
          :wrapper-col="{ sm: { span: 24 - 3 } }"
        >
          <a-col :sm="{ span: 24 }">
            <a-row class="myform-vertical" layout="vertical" v-if="uoms">
              <template v-for="(item, index) in uoms.filter(i => !i.is_deleted)" :key="index">
                <a-input
                  size="small"
                  type="hidden"
                  v-model:value="item.id"
                  :disabled="formState.readOnly || item.produk_id !== null"
                />
                <a-row class="form-row" :gutter="[8, 8]">
                  <a-col>
                    <a-form-item
                      label="Satuan"
                      label-align="left"
                      :wrapper-col="{ sm: { span: 24 } }"
                      :name="['uoms', index, 'uom']"
                      :rules="{
                        required: true,
                        message: 'Satuan tidak boleh kosong!',
                      }"
                      :help="
                        Array.isArray(item.errors)
                          ? item.errors.find(i => i.field === 'uom')
                            ? item.errors.find(i => i.field === 'uom').message
                            : undefined
                          : undefined
                      "
                      :validate-status="
                        Array.isArray(item.errors)
                          ? item.errors.find(i => i.field === 'uom') !== null
                            ? 'error'
                            : undefined
                          : undefined
                      "
                    >
                      <filter-satuan
                        :mode="null"
                        model-id="name"
                        v-model:value="item.uom"
                        placeholder="Pilih Satuan"
                        :disabled="formState.readOnly || item.produk_id !== null"
                        style="width: 100%"
                      ></filter-satuan>
                    </a-form-item>
                  </a-col>
                  <a-col>
                    <a-form-item
                      label="Konversi"
                      :wrapper-col="{ sm: { span: 24 } }"
                      label-align="left"
                      :rules="{
                        required: true,
                      }"
                    >
                      <a-input
                        type="number"
                        v-model:value="item.conversion"
                        :disabled="formState.readOnly || item.produk_id !== null"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col>
                    <a-form-item
                      v-if="item.uom === 'TON'"
                      style="width: 90px"
                      label="Hasil Konversi "
                      :wrapper-col="{ sm: { span: 24 } }"
                      label-align="left"
                    >
                      <a-input :value="Number(1 / item.conversion)" disabled />
                    </a-form-item>
                  </a-col>
                  <a-col>
                    <a-form-item label-align="left">
                      <a-button
                        @click="removeRow(item)"
                        size="small"
                        type="danger"
                        style="margin-top: 22px"
                        :wrapper-col="{ sm: { span: 24 } }"
                        :disabled="formState.readOnly"
                        ><i class="fa fa-remove"></i
                      ></a-button>
                    </a-form-item>
                  </a-col>
                </a-row>
              </template>
            </a-row>
            <!-- add function -->
            <template v-if="!formState.readOnly">
              <a-form-item
                label-align="left"
                :wrapper-col="{ sm: { span: 24 - 4 - 8 } }"
                :colon="false"
              >
                <a-button type="primary" @click="addUom"
                  ><i class="fa fa-plus"></i> Tambah Satuan</a-button
                >
              </a-form-item>
            </template>
          </a-col>
        </a-form-item>

        <a-row class="form-row" _style="margin-top:-20px;">
          <a-col :sm="24">
            <a-form-item
              label="Pilih Kategori"
              label-align="left"
              :label-col="{ sm: { span: 3 } }"
              :wrapper-col="{ sm: { span: 15 - 3 } }"
              :help="error.category_id.message"
              :validate-status="error.category_id.status"
              required
              name="category_id"
            >
              <filter-category
                style="width: 100%"
                :mode="null"
                v-model:value="category_id"
              ></filter-category>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row class="form-row" _style="margin-top:-20px;">
          <a-col :sm="24">
            <a-form-item
              label="Pilih Sub Kategori"
              label-align="left"
              :label-col="{ sm: { span: 3 } }"
              :wrapper-col="{ sm: { span: 15 - 3 } }"
              :help="error.subcategory_id.message"
              :validate-status="error.subcategory_id.status"
              required
              name="subcategory_id"
            >
              <filter-sub-category
                style="width: 100%"
                :mode="null"
                v-model:value="subcategory_id"
                v-model:category="category_id"
              ></filter-sub-category>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row class="form-row" _style="margin-top:-20px;">
          <a-col :sm="24">
            <a-form-item
              label="Pilih Type"
              label-align="left"
              :label-col="{ sm: { span: 3 } }"
              :wrapper-col="{ sm: { span: 15 - 3 } }"
              :help="error.type.message"
              :validate-status="error.type.status"
              required
              name="type"
            >
              <filter-product-type
                style="width: 100%"
                :mode="null"
                v-model:value="type"
              ></filter-product-type>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row class="form-row" _style="margin-top:-20px;">
          <a-col :sm="24">
            <a-form-item
              label="Pilih Brands"
              label-align="left"
              :label-col="{ sm: { span: 3 } }"
              :wrapper-col="{ sm: { span: 15 - 3 } }"
              :help="error.brand_id.message"
              :validate-status="error.brand_id.status"
            >
              <filter-brand
                style="width: 100%"
                v-model:value="brand_id"
                :mode="null"
              ></filter-brand>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row class="form-row" _style="margin-top:-20px;">
          <a-col :sm="24">
            <a-form-item
              label="Pilih Suplier"
              label-align="left"
              :label-col="{ sm: { span: 3 } }"
              :wrapper-col="{ sm: { span: 15 - 3 } }"
              :help="error.supplier_id.message"
              :validate-status="error.supplier_id.status"
            >
              <filter-supplier
                style="width: 100%"
                v-model:value="supplier_id"
                :mode="null"
              ></filter-supplier>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row class="form-row" _style="margin-top:-20px;">
          <a-col :sm="24">
            <a-form-item
              label="Harga Suplier"
              label-align="left"
              :label-col="{ sm: { span: 3 } }"
              :wrapper-col="{ sm: { span: 15 - 3 } }"
              name="price"
              :help="error.price.message"
              :validate-status="error.price.status"
            >
              <a-input-number
                placeholder="Harga Suplier"
                style="width: 100%;"
                v-model:value="price"
                :disabled="formState.readOnly"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row class="form-row" _style="margin-top:-20px;">
          <a-col :sm="24">
            <a-form-item
              label="Detail Produk"
              label-align="left"
              :label-col="{ sm: { span: 3 } }"
              :wrapper-col="{ sm: { span: 15 - 3 } }"
              :help="error.description.message"
              :validate-status="error.description.status"
            >
              <a-textarea
                v-model:value="description"
                placeholder=""
                :auto-size="{ minRows: 2, maxRows: 5 }"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row class="form-row" _style="margin-top:-20px;">
          <a-col :sm="24">
            <a-form-item
              label="Unggah Foto Produk"
              label-align="left"
              :label-col="{ sm: { span: 3 } }"
              :wrapper-col="{ sm: { span: 15 - 3 } }"
              :help="error.image.message"
              :validate-status="error.image.status"
            >
              <a-input
                required
                v-if="!image || image === 'null'"
                type="file"
                accept="image/png, image/gif, image/jpeg"
                @change="e => onFileChange(e, 'image')"
              ></a-input>
              <a-button
                v-else
                @click="
                  () => {
                    image = null
                    imageBase = null
                  }
                "
                >Hapus Foto</a-button
              >
              <img
                v-if="image !== 'null'"
                style="display: block; max-width:150px;
            width: auto;
            height: auto;"
                :src="typeof image === 'string' ? image : imageBase"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-button
          :loading="isPosting"
          key="submit"
          type="primary"
          @click="submit"
          :hidden="formState.readOnly"
          >Simpan</a-button
        >
      </a-form>
    </a-card>
  </div>
</template>

<script>
import { defineComponent, reactive, toRaw, ref, toRefs, toRef, onMounted } from 'vue'
import { Modal, message } from 'ant-design-vue'
import apiClient from '@/services/axios'
import FilterBrand from '@/components/filter/FilterBrand'
import FilterCategory from '@/components/filter/FilterCategory'
import FilterSupplier from '@/components/filter/FilterSupplier'
import FilterProductType from '@/components/filter/FilterProductType'
import FilterSubCategory from '@/components/filter/FilterSubCategory'
import { serialize } from 'object-to-formdata'
import { FilterSatuan } from '@/components/filter'

const initial = {
  id: null,
  code: null,
  name: null,
  description: null,
  image: null,
  brand_id: null,
  category_id: null,
  supplier_id: null,
  //todo: kurang api
  subcategory_id: null,
  type: null,
  uom: null,
  barcode: null,
  price: null,
  uoms: [],
  codes: [],
  readOnly: false,
}

export default defineComponent({
  components: {
    FilterBrand,
    FilterSubCategory,
    FilterCategory,
    FilterSatuan,
    FilterSupplier,
    FilterProductType,
  },
  setup() {
    const formRef = ref()

    const formState = reactive(Object.assign({}, initial))

    const state = reactive({
      reference: {
        id: null,
        code: null,
        name: null,
      },
    })

    const isPosting = ref(false)
    const imageBase = ref('')
    const imageToBase64 = (file, to) => {
      var reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        to.value = reader.result
      }
      reader.onerror = function(error) {
        console.log('Error: ', error)
      }
    }

    const checkCodeSAP = () => {
      console.log(`code`, error.code)
    }

    // manual set invalid validation
    const errorProps = Object.fromEntries(
      Object.entries(initial).map(([key, val]) => [key, { status: undefined, message: undefined }]),
    )
    const error = reactive(errorProps)

    const cleanError = () => {
      Object.keys(error).forEach(key => {
        console.log(error, key)
        error[key].status = undefined
        error[key].message = undefined
      })
    }

    const submit = async () => {
      cleanError()

      try {
        await formRef.value.validate()
      } catch (error) {
        console.error('validate', error)
        return
      }

      Modal.confirm({
        title: 'Konfirmasi Tambah Produk',
        content: 'Apakah anda ingin melanjutkan Ajukan Produk ?',

        onOk() {
          // TODO: validasi
          isPosting.value = true
          const form_data = serialize(
            {
              ...formState,
            },
            { indices: true, booleansAsIntegers: true },
          )

          apiClient
            .post('/api/products', form_data)
            .then(({ data }) => {
              // reset
              Object.assign(formState, initial)
              formState.uoms.splice(0, formState.uoms.length)
              formState.codes.splice(0, formState.codes.length)
              isPosting.value = false
              imageBase.value = null

              message.success('Berhasil disimpan')
            })
            .catch(async e => {
              console.log({ e, response: e.response })
              // TODO: khusus 422
              let messageError = ''
              if (e.response.status && e.response.data) {
                const { status, data } = await e.response
                if (status === 422) {
                  data.forEach(({ field, message }) => {
                    if (message === 'Uom tidak boleh double.') {
                      messageError = 'Satuan tidak boleh sama!'
                    } else if (message.indexOf('already') > 0) {
                      messageError = 'Kode SAP sudah tersedia!'
                      error.code.status = 'error'
                      error.code.message = messageError
                    } else {
                      messageError = message
                    }
                    // error[field].status = 'error'
                    // error[field].message = message
                  })
                } else {
                  cleanError()
                }
              }
              message.error(messageError)
            })
            .finally(e => {
              isPosting.value = false
            })
        },
        onCancel() {},
      })
    }
    const onFileChange = async (e, type) => {
      var files = e.target.files || e.dataTransfer.files
      console.log(files.length, type)
      if (!files.length) return
      var filesize = (files[0].size / 1024 / 1024).toFixed(4) // MB
      if (filesize > 2.0) {
        message.warning('Ukuran harus di bawah 2MB!', 5.0)
        if ('image' === type) {
          formState.image = false
        }
        await nextTick()
        if ('image' === type) {
          formState.image = true
        }
        return
      }

      formState.image = files[0]
      imageToBase64(files[0], imageBase)
    }

    const rules = {
      name: {
        required: true,
        message: 'Masukan Nama Produk',
        type: 'string',
      },
      code: {
        required: true,
        message: 'Masukan Kode SAP',
        type: 'string',
      },
      category_id: {
        required: true,
        message: 'Pilih Kategori',
      },
      subcategory_id: {
        required: true,
        message: 'Pilih Sub Category',
      },
      type: {
        required: true,
        message: 'Pilih Type',
      },
    }
    const uom = ref({
      id: null,
      uom: null,
      conversion: null,
      produk_id: null,
    })

    const uoms = toRef(formState, 'uoms')
    const addUom = () => {
      if (uoms.value.filter(v => v.uom === null).length > 0) {
        message.warning('Pastikan satuan terisi!')
        return
      }
      const m = Object.assign({}, uom.value)
      uoms.value.push(m)
    }

    const removeRow = item => {
      const index = uoms.value.indexOf(item)
      if (item.produk_id === null) uoms.value.splice(index, 1)
      else {
        item.is_deleted = true
      }
    }

    /// vendor code
    const addReferenceCode = () => {
      if (formState.codes.filter(v => v.code === null).length > 0) {
        message.warning('Pastikan kode referensi produk terisi!')
        return
      }
      const m = Object.assign({}, state.reference)
      formState.codes.push(m)
    }

    const removeReferenceRow = item => {
      const index = formState.codes.indexOf(item)
      if (item.id === null) formState.codes.splice(index, 1)
      else {
        item.is_deleted = true
      }
    }

    onMounted(() => {
      // add 1 row default
      addUom()
    })

    return {
      ...toRefs(formState),
      formState,
      submit,
      isPosting,
      onFileChange,
      imageBase,
      imageToBase64,
      error,
      formRef,
      rules,
      addUom,
      removeRow,
      addReferenceCode,
      removeReferenceRow,
      state,
    }
  },
})
</script>

<style lang="scss" scoped>
@import '@/css/form.scss';
</style>
